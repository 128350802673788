
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
//@import url(https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.7/css/bootstrap.min.css);
//@import url(https://bootswatch.com/flatly/bootstrap.min.css);
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.text-info,
.text-info:hover {
    color: $brand-info;
}

.text-danger,
.text-danger:hover {
    color: $brand-danger;
}

.frame-item {
    cursor: pointer;
}


.frame-item.active {
    outline: 1px solid $gray-dark;
    border-radius: $border-radius-base;
}